
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/form/switch/BasicUsage.vue";
import EUITextDescription from "@/views/resources/documentation/element-ui/form/switch/TextDescription.vue";
import EUIExtendedValueTypes from "@/views/resources/documentation/element-ui/form/switch/ExtendedValueTypes.vue";
import EUIDisabled from "@/views/resources/documentation/element-ui/form/switch/Disabled.vue";
import EUILoading from "@/views/resources/documentation/element-ui/form/switch/Loading.vue";

export default defineComponent({
  name: "switch",
  components: {
    EUIBasicUsage,
    EUITextDescription,
    EUIExtendedValueTypes,
    EUIDisabled,
    EUILoading
  },
  setup() {
    setCurrentPageTitle("Switch");
  }
});
